import React from 'react';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import BookTeaser from '../../../BookTeaser';
import data from '../../../../data/socialPhobia';

import styles from './socialPhobia.module.scss';

const SocialPhobia = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498616697/anxiety.jpg';
  const image1 = 'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498618509/phobia.jpg';

  return (
    <main>
      <PageHeader
        pageCategory="Specialties"
        pageName="Social Phobia & Anxiety"
        headerImage={background}
      />

      <div className={styles.bookWrapper}>
        <BookTeaser bookName="Let Go of Anxiety" />
      </div>

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.overview }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Treatment</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.treatment }} />
      </div>

      <QuoteImage image={image1} maxWidth={430} quote={data.quote1} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Specific Phobia</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.details }} />
      </div>
    </main>
  );
};

export default SocialPhobia;
