const socialPhobia = {
  quote1:
    '<p>“I am not a product of my circumstances. I am a product of my decisions.”</p><p>STEPHEN COVEY</p>',
  overview:
    'People suffering from this type of phobia have persistent fear of “social performance” situations in which they feel they will be judged by others and embarrassment may occur. Individuals recognize this fear is excessive. However children may not recognize the fear as being unreasonable. Usually, individuals will stay away from social circumstances in order to avoid the discomfort they feel. People with social phobia experience symptoms of anxiety such as: palpitations, tremors, sweating, gastrointestinal discomfort, diarrhea, muscle tension, blushing, and confusion. Eventually their normal routine and functioning is affected due to the distress they experience. Individuals with this disorder avoid people, they feel alone, and may experience depression as well.',
  treatment:
    'Treatment will include ACT (Acceptance & Commitment Therapy) and Cognitive Behavioral Therapy (CBT) along with opportunities to practice what is taught by doing social exposures either during sessions or during the week. You will learn to become aware of your thoughts. As you learn to view them with a different perspective, you will get untangled from unhelpful thoughts that will have less influence over your behavior.<br/><br/>You will learn to make room and open up to your feelings without having to fight them. You will learn how to connect with the present moment through mindfulness training and feel empowered to focus more on your values and less on anxiety. Treatment is tailored to fit clients’ individual circumstances.',
  details:
    'Individuals may experience excessive anxiety due to being exposed to a specific object or situation they fear. Because of their anxiety they begin to avoid those specific objects or situations. Usually the fear and anxiety increase causes them to go to extreme measures to avoid feeling that way.<br/><br/>If you suffer from a specific phobia, there is help and hopefully you’ve learned that avoidance has not solved your problem thus far.<br/><br/>Treatment will include CBT and ACT skills that include mindfulness training. You and your therapists will create a treatment plan that fits your specific needs. You will learn the necessary steps to help you look at social situations with a different set of eyes.',
};

export default socialPhobia;
